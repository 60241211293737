.rc-select {
    display: inline-block;
    font-size: 12px;
    width: 120px !important;
    position: relative;
    margin-top: 3px;
}
.rc-select-disabled, .rc-select-disabled input {
    cursor: not-allowed;
}
.rc-select-disabled .rc-select-selector {
    opacity: 0.3;
}
.rc-select-show-arrow.rc-select-loading .rc-select-arrow-icon::after {
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    border: 2px solid #999;
    border-top-color: transparent;
    border-bottom-color: transparent;
    transform: none;
    margin-top: 4px;
    animation: rcSelectLoadingIcon 0.5s infinite;
}
.rc-select .rc-select-selection-placeholder {
    opacity: 0.4;
    pointer-events: none;
}
.rc-select .rc-select-selection-search-input {
    appearance: none;
}
.rc-select .rc-select-selection-search-input::-webkit-search-cancel-button {
    display: none;
    appearance: none;
}
.rc-select-single .rc-select-selector {
    display: flex;
    position: relative;
}
.rc-select-single .rc-select-selector .rc-select-selection-search {
    width: 100%;
}
.rc-select-single .rc-select-selector .rc-select-selection-search-input {
    width: 100%;
}
.rc-select-single .rc-select-selector .rc-select-selection-item, .rc-select-single .rc-select-selector .rc-select-selection-placeholder {
    position: absolute;
    top: 1px;
    left: 3px;
    pointer-events: none;
}
.rc-select-single:not(.rc-select-customize-input) .rc-select-selector {
    padding: 1px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}
.rc-select-single:not(.rc-select-customize-input) .rc-select-selector .rc-select-selection-search-input {
    border: none;
    outline: none;
    background: rgba(255,0,0,0.2);
    width: 100%;
}
.rc-select-multiple .rc-select-selector {
    display: flex;
    flex-wrap: wrap;
    padding: 1px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-item {
    flex: none;
    background: #bbb;
    border-radius: 4px;
    margin-right: 2px;
    padding: 0 8px;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-item-disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-overflow {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-overflow-item {
    flex: none;
    max-width: 100%;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search {
    position: relative;
    max-width: 100%;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-input, .rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
    padding: 1px;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
    z-index: 999;
    white-space: nowrap;
    position: none;
    left: 0;
    top: 0;
    visibility: hidden;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-input {
    border: none;
    outline: none;
    background: rgba(255,0,0,0.2);
    width: 100%;
}
.rc-select-allow-clear.rc-select-multiple .rc-select-selector {
    padding-right: 20px;
}
.rc-select-allow-clear .rc-select-clear {
    position: absolute;
    right: 20px;
    top: 0;
}
.rc-select-show-arrow.rc-select-multiple .rc-select-selector {
    padding-right: 20px;
}
.rc-select-show-arrow .rc-select-arrow {
    pointer-events: none;
    position: absolute;
    right: 5px;
    top: 0;
}
.rc-select-show-arrow .rc-select-arrow-icon::after {
    content: '';
    border: 5px solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    border-top-color: #999;
    transform: translateY(5px);
}
.rc-select-focused .rc-select-selector {
    /*border-color: blue !important;*/
}
.rc-select-dropdown {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    min-height: 100px;
    position: absolute;
    background: #fff;
}
.rc-select-dropdown-hidden {
    display: none;
}
.rc-select-item {
    font-size: 16px;
    line-height: 1.5;
    padding: 4px 16px;
}
.rc-select-item-group {
    color: #999;
    font-weight: bold;
    font-size: 80%;
}
.rc-select-item-option {
    position: relative;
}
.rc-select-item-option-grouped {
    padding-left: 24px;
}
.rc-select-item-option .rc-select-item-option-state {
    display: none;
}
.rc-select-item-option-active {
    background: darkgrey;
}
.rc-select-item-option-disabled {
    color: #999;
}
.rc-select-item-empty {
    text-align: center;
    color: #999;
}
.rc-select-selection__choice-zoom {
    transition: all 0.3s;
}
.rc-select-selection__choice-zoom-appear {
    opacity: 0;
    transform: scale(0.5);
}
.rc-select-selection__choice-zoom-appear.rc-select-selection__choice-zoom-appear-active {
    opacity: 1;
    transform: scale(1);
}
.rc-select-selection__choice-zoom-leave {
    opacity: 1;
    transform: scale(1);
}
.rc-select-selection__choice-zoom-leave.rc-select-selection__choice-zoom-leave-active {
    opacity: 0;
    transform: scale(0.5);
}
.rc-select-dropdown-slide-up-enter, .rc-select-dropdown-slide-up-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08,0.82,0.17,1);
    animation-play-state: paused;
}
.rc-select-dropdown-slide-up-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6,0.04,0.98,0.34);
    animation-play-state: paused;
}
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-bottomLeft, .rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-bottomLeft {
    animation-name: rcSelectDropdownSlideUpIn;
    animation-play-state: running;
}
.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-bottomLeft {
    animation-name: rcSelectDropdownSlideUpOut;
    animation-play-state: running;
}
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-topLeft, .rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-topLeft {
    animation-name: rcSelectDropdownSlideDownIn;
    animation-play-state: running;
}
.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-topLeft {
    animation-name: rcSelectDropdownSlideDownOut;
    animation-play-state: running;
}
@keyframes rcSelectDropdownSlideUpIn {
    0% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
    }
}
@keyframes rcSelectDropdownSlideUpOut {
    0% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(0);
    }
}
@keyframes rcSelectLoadingIcon {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
