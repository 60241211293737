.rc-pagination-item-active {
    border-color: #525F7F;
}

.rc-pagination-item-active a {
    color: #525F7F;
}

.rc-pagination-item:hover {
    border-color: #525F7F;
}

.rc-pagination-item:hover a {
    color: #525F7F;
}

.rc-pagination-options-quick-jumper > input {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}