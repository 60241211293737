body {
  margin: 0;
}

body * {
  font-family: "Open Sans", sans-serif !important;
}

::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb{
  background: linear-gradient(150deg, #D9D9D9 77%,#D9D9D9 77%);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover{
  background: linear-gradient(150deg, #1C0066 77%,#1C0066 77%);
}
::-webkit-scrollbar-track{
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: inset 7px 10px 12px 0px #F0F0F0;
}